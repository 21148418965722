<template>
  <div class="login-page">
    <div class="topBar">
      <img :src="logo">
      <div class="topBar-center-menu">
        <div class="topBar-menu-item">首页</div>
        <div class="topBar-menu-item">功能</div>
        <div class="topBar-menu-item">白皮书</div>
        <div class="topBar-menu-item">关于</div>
      </div>
      <div class="topBar-right-demo">
        <div class="hightlight-orange">预约演示</div>
      </div>
    </div>
    <div class="login-container">
      <div class="headPage">
        <div class="title-background-shading shading-1">
          <div class="shading shading-round"></div>
          <div class="shading shading-rect shading-rect-1"></div>
          <div class="shading shading-round"></div>
          <div class="shading shading-rect shading-rect-2"></div>
        </div>
        <div class="title-background-shading shading-2">
          <div class="shading shading-rect shading-rect-1"></div>
          <div class="shading shading-round"></div>
          <div class="shading shading-rect shading-rect-2"></div>
          <div class="shading shading-round"></div>
        </div>
        <div class="title-background">
        </div>
        <div id="login-container">
          <AuthorizeUserComponent :mission-type="'login'"
                                  @signupSuccess="onSignupSuccess"
                                  @loginSuccess="onLoginSuccess">

          </AuthorizeUserComponent>
        </div>
      </div>
    </div>
    <div class="companyPage">
      <!--    <div class="companyTitle">公司简介</div>-->
      <div class="pageCenter">
        <div class="companyItem">
          <div class="description">
            <div class="item-des-title">简单，高效</div>
            <div class="itemDesc">简单的几步操作，即可将Word文档转化成为智能模板。</div>
            <div class="itemDesc">利用交互式问答，快速生成新文档。</div>
            <div class="item-delimiter"></div>
            <div class="itemDescImages">
              <img :src="icon_one">
              <img :src="icon_two">
              <img :src="icon_three">
            </div>
          </div>
          <div class="itemImg">
            <div class="item-image item-image-1"></div>
          </div>
        </div>
        <div class="companyItem">
          <div class="itemImg">
            <div class="item-image item-image-2"></div>
          </div>
          <div class="description">
            <div class="item-des-title">减少错漏</div>
            <div class="item-partition">
              <div class="item-delimiter"></div>
            </div>

            <div class="itemDesc">在频繁修改合同模板时，是否难以避免遗漏或前后不一致？</div>
            <div class="itemDesc">智能模板，让工作成果零错漏。</div>
          </div>
        </div>
        <div class="companyItem">
          <div class="description">
            <div class="item-delimiter"></div>
            <div class="item-des-title">管理组织的<span style="color:#0078ff">Know-How</span></div>
            <div class="itemDesc">让知识从个人经验沉淀为公司资产</div>
            <div class="itemDesc">利用交互式问答让知识的“取用”更便利</div>
          </div>
          <div class="itemImg">
            <div class="item-image item-image-3"></div>
          </div>
        </div>
        <div class="companyItem">
          <div class="itemImg">
            <div class="item-image item-image-4"></div>
            <div class="item-image-caption">文档自动化工具的三大核心优势</div>
          </div>
          <div class="description">
            <div class="item-des-title">获取白皮书</div>
            <div class="item-des-tip tip-1">填写您的联系信息，免费获取该白皮书</div>
            <form id="reward-whiteBook-form" method="post" class="el-form login-content-form"
                  action="" novalidate="novalidate">
              <input type="hidden" name="login_by" value="account">
              <div class="el-form-item is-required el-form-item--small">
                <div class="whitebook-input el-form-item__content" style="margin-left: 0px;">
                  <div class="el-input el-input--small">
                    <input type="text" autocomplete="off" placeholder="姓名" name="name"
                           id="username" class="whitebook-input-real el-input__inner">
                  </div>
                </div>
              </div>
              <div class="whitebook-input el-form-item password-wrap is-required el-form-item--small">
                <div class="el-form-item__content" style="margin-left: 0px;">
                  <div class="phonecode-input-wrap el-input el-input--small">
                    <input type="text" autocomplete="off" placeholder="Email" name="email"
                           id="userEmail" class="whitebook-input-real el-input__inner">
                  </div>
                  <span class="el-input__suffix" style="top: -14px; right: -12px; height: 35px;"> <span
                      class="el-input__suffix-inner"> <i
                      class="el-input__icon el-icon-view el-input__clear"></i> </span> </span>
                </div>
              </div>
              <div class="item-des-tip">提交此申请代表您同意MetaGo使用您的信息与伙伴服务进<br/>
                行交流。您可以在此阅读我们的<a href="/privacypolicy.html" target="_blank" style="color:#0078ff"
                                 id="userPrivacyContrast">《用户隐私协议》</a>
                或与我们<br>联系
              </div>
              <div class="login-btn-wrap">
                <button type="button" class="el-button el-button--primary"
                        id="downloadWhiteBookBtn">
                  <span>获取</span></button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
    <div class="footBar">
      <div class="footCenter">
        <div class="companyImg-proxy">
          <div class="companyImg"></div>
        </div>
        <div class="companyDesc">
          <p>联系电话：+86 17600619938</p>
          <!--            <p class="companyDesc-center">北京分部地址：北京市朝阳区八里庄西里远洋国际中心C座2604室</p>-->
          <!--            <p>上海总部地址：上海市浦东新区南汇新城镇环湖西二路888号C楼</p>-->
          <p>北京密塔网络科技有限公司：北京市朝阳区东四环中路60号楼26层2605室</p>
          <p>公司简介：北京密塔网络科技有限公司成立于2019年5月，是法律人工智能领域的一家新锐科技公司，致力于运用人工智能技术为法律人打造得心应手的生产工具，大幅提升法律行业工作效率，让法律人享受科技的便利。
            <br>
            目前，密塔科技已经成立北京和成都两大研发中心，在智能法律检索、多语种法律文件翻译等方向上进行技术研发和产品落地。
          </p>
        </div>
      </div>
      <a class="credential" href="https://beian.miit.gov.cn/" target="_blank"><span class="footer-footer-left"> © 秘塔科技 | 沪ICP备18046118号-6</span></a>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import logo from "../assets/images/logo.png"
import icon_one from "../assets/images/icon_one.png"
import icon_two from "../assets/images/icon_two.png"
import icon_three from "../assets/images/icon_three.png"
import "../assets/js/jquery-easing"
import {uuidGen} from "../util";
import config from "../config";
import {
  checkPhoneCode,
  checkValidCode, getMyInfo,
  getPhoneCode,
  getValid,
  loginByAccount,
  loginByPhone,
  loginUranus, requestLogin
} from "../api/api";
import {JSEncrypt} from "jsencrypt";
import AuthorizeUserComponent from "../components/account/AuthorizeUserComponent";

let limit = 5;
let isMoving = false;
let duration = 700;

$(document).ready(function () {
  let companyPage = document.querySelector('.companyPage');
  let menuItems = document.querySelectorAll('.topBar-menu-item');
  let companyItem = document.querySelector('.companyItem');
  let whiteBookItem = document.querySelector('.companyItem:last-of-type');
  let topBar = document.querySelector('.topBar');
  let footBar = document.querySelector('.footBar');
  let headPage = document.querySelector('.headPage');
  let appointment = document.querySelector('.topBar-right-demo');
  let page = document.querySelector('.login-page');


  let homePageY;
  let functionY;
  let whiteBookY;
  let aboutBookY;
  let scrollRules;
  refreshCoefficient();


  window.addEventListener('resize', refreshCoefficient);
  /**
   * 绑定首页滚动事件
   */
  menuItems[0].onclick = function () {
    if (isMoving) {
      return;
    }
    isMoving = true;
    setActiveTopItem(Array.from(menuItems), menuItems[0], 'topBar-menu-item--active');
    // scrollWindow(0,homePageY);
    jqueryScrollTop(homePageY, duration, () => {
      isMoving = false;
      checkTopBarState();
    });
  }

  /**
   * 绑定功能滚动事件
   */
  menuItems[1].onclick = function () {
    if (isMoving) {
      return;
    }
    isMoving = true;
    setActiveTopItem(Array.from(menuItems), menuItems[1], 'topBar-menu-item--active');

    // scrollWindow(0,functionY);
    jqueryScrollTop(functionY, duration, () => {
      isMoving = false;
      checkTopBarState();
    });
  }

  /**
   * 绑定白皮书滚动事件
   */
  menuItems[2].onclick = function () {
    if (isMoving) {
      return;
    }
    isMoving = true;
    setActiveTopItem(Array.from(menuItems), menuItems[2], 'topBar-menu-item--active');
    // scrollWindow(0,whiteBookY);
    jqueryScrollTop(whiteBookY, duration, () => {
      isMoving = false;
      checkTopBarState();
    });
  }

  menuItems[3].onclick = function () {
    if (isMoving) {
      return;
    }
    isMoving = true;
    setActiveTopItem(Array.from(menuItems), menuItems[3], 'topBar-menu-item--active');
    // scrollWindow(0,aboutBookY);
    jqueryScrollTop(aboutBookY, duration, () => {
      isMoving = false;
      checkTopBarState();
    });
  }

  let checkTopBarState = generateCheckTopBarState(scrollRules, Array.from(menuItems), 'topBar-menu-item--active');
  checkTopBarState();
  page.addEventListener('scroll', checkTopBarState);

  window.addEventListener('resize', () => {
    page.removeEventListener('scroll', checkTopBarState);
    checkTopBarState = generateCheckTopBarState(scrollRules, Array.from(menuItems), 'topBar-menu-item--active');
    page.addEventListener('scroll', checkTopBarState);
  })

  function generateCheckTopBarState(rules, doms, additionClass) {

    return function () {
      let y = page.scrollTop

      console.log(page.scrollHeight, page.scrollTop, y)
      if (isMoving) {
        return;
      }
      let activeIndex = -1;
      rules.forEach((item, index) => {
        console.log(item)
        if (y >= item[0] && y <= item[1]) {
          activeIndex = index
        }
      })
      doms.forEach(dom => dom.classList.remove(additionClass));
      doms[activeIndex].classList.add(additionClass);
    }
  }

  /**
   * 获取各模块的位置信息
   */
  function refreshCoefficient() {
    homePageY = 0;
    functionY = companyPage.offsetTop - 75;
    whiteBookY = whiteBookItem.offsetTop + whiteBookItem.clientHeight - window.innerHeight;
    aboutBookY = footBar.offsetTop + footBar.clientHeight - window.innerHeight;
    // if(whiteBookY + window.innerHeight > page.scrollHeight){
    //   whiteBookY = page.scrollHeight - window.innerHeight;
    // }
    scrollRules = [
      [0, functionY],
      [functionY, whiteBookY],
      [whiteBookY, aboutBookY],
      [aboutBookY, page.scrollHeight - window.innerHeight]
    ];
  }

  function setActiveTopItem(siblingDoms, target, additionClass) {
    siblingDoms.forEach(dom => dom.classList.remove(additionClass));
    target.classList.add(additionClass);
  }
})

function jqueryScrollTop(value, duration, callBack) {
  $('.login-page').animate({scrollTop: value + 'px'}, duration + '', 'easeInOutCubic', callBack);
}


export default {
  name: "Login",
  components: {AuthorizeUserComponent},
  data() {

    return {
      labelPosition: 'left',
      logo: logo,
      icon_one,
      icon_two,
      icon_three
    }

  },
  mounted() {
  },
  methods: {
    onLoginSuccess(){
      if(this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      }
      else{
        this.$router.push('/index');
      }
    },
    onSignupSuccess(){
      if(this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      }
      else{
        this.$router.push('/index');
      }
    }

  },
  beforeRouteLeave(){
    getMyInfo();
  }
}
</script>

<style scoped>
.item-des-title {
  font-weight: bold;
  color: #0a4a9d;
  font-size: 1.96vw;
  white-space: nowrap;
}

.item-delimiter {
  position: absolute;
  height: 0.208vw;
  width: 6vw;
  background-color: #0a4a9d;

  border-radius: 5px;
}

.topBar {
  display: flex;
  width: var(--topbarWidth);
  height: var(--topbarHeight);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  align-items: center;
}

.topBar img {
  width: 18.6vw;
  height: auto;
  margin-left: 3.4375vw;
}

.topBar-center-menu {
  margin-left: 3.125vw;
}

.topBar-menu-item {
  width: 7.3vw;
  height: var(--topbarHeight);
  line-height: var(--topbarHeight);
  text-align: center;
  cursor: pointer;
}

.topBar-menu-item--active, .topBar-menu-item:hover {
  color: #fff;
  background-color: rgb(60, 74, 169);
}

.topBar-center-menu {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

.topBar-right-demo {
  position: absolute;
  right: 0;
  width: 6.8vw;
  height: 2.76vw;
  text-align: center;
  line-height: 2.76vw;
  margin-right: 10vw;
}

.hightlight-orange {
  border-radius: 10px;
  cursor: pointer;
  font-size: 0.78vw;
  background: rgb(255, 146, 4);
  color: #fff;
  position: relative;
}

.hightlight-orange::after {
  content: '';
  background-color: transparent;
  width: 100%;
  box-shadow: 1px 1px 10px 3px rgba(255, 146, 4, .5);
  position: absolute;
  left: 0;
  bottom: 0;
}

.login-container {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background: rgb(60, 74, 169);
  margin-top: var(--topbarHeight);
  overflow: hidden;
}

.login-container li {
  /*line-height: 60px;*/
  display: inline-block;
  font-size: 16px;
  color: #333;
  margin: 0 5px;
}

.headPage {
  width: 80%;
  padding: 0 10%;
  display: flex;
  align-items: center;
  height: 41.7vw;
  min-height: 500px;
  min-width: 800px;
  position: relative;
}

.shading-1 {
  align-items: flex-end;
  top: 0;
}

.shading-1 .shading-rect-1 {
  transform: translateY(-2.6vw);
}

.shading-2 {
  bottom: 0;
}

.shading-2 .shading-rect-1 {
  transform: translateY(2.6vw);
}

.shading {

  width: 24vw;
  height: 24vw;
  background-color: #4451ab;

}

.shading-round {
  border-radius: 12vw;
}

.shading-rect {

}

.content-tab{

}
.title-background {
  position: relative;
  width: 46.875vw;
  min-width: calc(0.46875 * var(--minBaseWidth));
  height: 25.26vw;
  min-height: calc(0.2526 * var(--minBaseWidth));
  background-image: url(../assets/images/img_one.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.companyPage {
  width: var(--topbarWidth);
  background-color: white;
}

.companyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 25vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

.pageCenter {
  margin: auto;
  min-width: 1000px;
  width: 80%;
}

.itemDescImages {
  margin-top: 4.58vw;
  margin-left: 11.5vw;
  text-align: left;
}

.itemDescImages img {
  width: 2.08vw;
  height: 2.08vw;
  margin-right: 3.33vw;
}

.companyItem:nth-of-type(1) .description .item-delimiter {
  bottom: 4.8vw;
  left: 11.5vw;
}

.companyItem:nth-of-type(1) .description .item-des-title {
  margin-left: 11.5vw;
  margin-bottom: 1.46vw;
  text-align: left;
}

.companyItem:nth-of-type(1) .itemDesc {

}

.companyItem:nth-of-type(2) .description {
  text-align: left;
}

.companyItem:nth-of-type(2) .item-des-title {
  margin-left: 5.8vw;
}

.companyItem:nth-of-type(2) .itemDesc {
  margin-left: 5.8vw;
  padding: 0;
}

.companyItem:nth-of-type(2) .description .item-delimiter {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.companyItem:nth-of-type(3) .item-des-title {
  text-align: left;
  margin-left: 9.9vw;
  margin-bottom: 1.25vw;
}

.companyItem:nth-of-type(3) .itemDesc {
  margin-left: 9.9vw;
}

.companyItem:nth-of-type(3) .description .item-delimiter {
  left: 9.9vw;
  top: -2.08vw;
}

.companyItem:nth-of-type(4) .description {
  text-align: left;
  padding-left: 5.8vw;
  box-sizing: border-box;
}

companyItem:nth-of-type(4) item-des-title {
  margin: 0;
}

.tip-1 {
  color: #1C4DC5 !important;
}

.item-des-tip {
  color: #0a4a9d;
  margin-top: .833vw;
  font-size: .83vw;
  margin-bottom: 1.25vw;
}

#reward-whiteBook-form .el-input__inner {
  width: 21.35vw;
  min-width: calc(0.2135 * var(--minBaseWidth));
  height: 2.97vw;
  border-radius: var(--bottomRadiusValue);
}

#reward-whiteBook-form .el-button {
  display: inline-block;
  position: relative;
  width: 7.2vw;
  height: 2.8vw;
  border-radius: var(--bottomRadiusValue);
  font-size: 0.67vw;
}

#downloadWhiteBookBtn::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  box-shadow: 1px 1px 21px 5px rgb(28 77 197 / 50%);
}

.temp-dialog {
  display: none;
  position: fixed;
  width: 250px;
  height: 299px;
  top: 50%;
  left: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px 1px #aaa;
  border-radius: 10px;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.temp-dialog-close {
  text-align: right;
  height: 27px;
  user-select: none;
  color: #0a4a9d;
  position: relative;
}

.temp-dialog .weixin-2DCode {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.temp-dialog .weixin-2DCode img {
  width: 100%;
  height: 100%;
}

.temp-dialog .temp-dialog-tip {
  color: #0a4a9d;
  font-weight: bold;
  text-align: left;
  margin: 10px 25px;
}

.temp-dialog-close-mark {
  position: absolute;
  cursor: pointer;
  right: 0px;
  padding: 10px;
  top: -2px;
}

.item-partition {
  position: relative;
  margin-left: 5.8vw;
  height: 3.0vw;
}

.whitebook-input {
  width: 21.35vw;
  min-width: calc(0.2135 * var(--minBaseWidth));
}

.whitebook-input-real {
  border-radius: var(--bottomRadiusValue);
}

.title-background-shading {
  display: flex;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
  left: 0;

  width: 100%;
  height: 5.2vw;
}

.shading-1 {
  align-items: flex-end;
  top: 0;
}

.shading-1 .shading-rect-1 {
  transform: translateY(-2.6vw);
}

.shading-2 {
  bottom: 0;
}

.shading-2 .shading-rect-1 {
  transform: translateY(2.6vw);
}

.shading {

  width: 24vw;
  height: 24vw;
  background-color: #4451ab;

}

.shading-round {
  border-radius: 12vw;
}

.shading-rect {

}

.itemDesc {
  font-size: 1.04vw;
  margin-left: 11.5vw;
  color: #999999;
  text-align: left;
  color: #0a4a9d;
  /*padding-right: clamp(calc(0.0458 * var(--minBaseWidth)),4.58vw,4.58vw);*/
}

#account-login-change-button{
  display: block;
  position: absolute;
  bottom: 2.70vw;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.04vw;
}
.itemImg .item-image {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.itemImg .item-image-1 {
  background-image: url(../assets/images/img_two.png);
  background-size: 22.17vw 21.46vw;
}

.itemImg .item-image-2 {
  background-image: url(../assets/images/img_three.png);
  background-size: 26.9vw 21.1vw;
}

.itemImg .item-image-3 {
  background-image: url(../assets/images/img_four.png);
  background-size: 26.2vw 17.4vw;
}

.itemImg .item-image-4 {
  background-image: url(../assets/images/img_five.png);
  background-size: 23vw 16.54vw;
  background-position-y: 3.125vw;
}

.companyItem .description {
  text-align: right;
  width: 50%;
  position: relative;
}

.companyItem .itemImg {
  width: 50%;
  height: 100%;
  background: #f3f8ff;
  position: relative;
}

.companyItem:nth-of-type(1) .itemImg {
  background-color: rgb(106, 121, 221);
}

.companyItem:nth-of-type(2) .itemImg {
  background-color: rgb(178, 211, 253);
}

.companyItem:nth-of-type(3) .itemImg {
  background-color: rgb(236, 246, 255);
}

.companyItem:nth-of-type(4) .itemImg {
  background-color: rgb(243, 248, 255);
}

.login-page::-webkit-scrollbar-track {
  border-radius: 14px;
}

.login-page::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
}

.login-page::-webkit-scrollbar-thumb {
  border: solid transparent;
  border-radius: 14px;
  background-color: rgba(38, 44, 57, .4);
}

.login-page::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.login-page {
  height: 100%;
  overflow-y: scroll;
}

.footBar {
  position: relative;
  width: var(--topbarWidth);
  background-color: rgb(22, 30, 47);
  margin-top: 1.5625vw;
  text-align: center;
  padding: 10px 0 10px 0;
  /*background: rgba(255, 255, 255, 1);*/
}

.footCenter {
  width: 85%;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
}

.companyImg-proxy {
  width: 6.1%;
  height: 0;
  padding-bottom: 6.1%;
  position: relative;
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  margin-right: 2%;
}

.companyImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-image: url("https://uranus-static.oss-accelerate.aliyuncs.com/xiezuocat/wechat-service.jpg");*/
  background-image: url(../assets/images/contact-qrcode.jpg);
  background-size: 100%;
  display: inline-block;
}

/*.footCenter::before{*/
/*    content:'';*/
/*    display: inline-block;*/
/*    height: 100%;*/
/*    width: 0;*/
/*    vertical-align: middle;*/
/*}*/
.companyDesc {
  display: inline-block;
  font-size: 0.78vw;
  max-width: 91.8%;
  color: #aaa;
  line-height: 2;
  white-space: normal;
}

.companyDesc p {
  margin: 0;
}

.companyDesc-center {
  margin: 1.04vw 0;
}

.companyItem .itemImg {
  width: 50%;
  height: 100%;
  background: #f3f8ff;
  position: relative;
}

.credential {
  display: block;
  color: #aaa;
  padding: 20px 0 10px 0;
  text-decoration: none;
  font-size: 14px;
}

.item-image-caption {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #1c4dc5;
  bottom: 3.33vw;
  font-size: 1.3vw;
  white-space: nowrap;
}


</style>

<style lang="scss">

.el-input-group {
  line-height: normal;
  display: inline-table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  width: 21.35vw;

  min-width: calc(0.2135 * var(--minBaseWidth));
}
.account-login-content{

  .el-input__inner{
    border-radius: var(--bottomRadiusValue);
    width: 21.35vw;
  }

}
.login-container__local {

  width: 30.625vw;
  min-width: calc(0.30625 * var(--minBaseWidth));
  height: 27.76vw;
  min-height: calc(0.2776 * var(--minBaseWidth));
  background-color: white;
  display: inline-block;
  border-radius: 4px;
  margin: 100px 0;
  margin-left: 2.23vw;
  position: relative;

  .login-tab-wrap {

    padding-top: 2.76vw;
    margin: 0;
    padding-left: 0;
    text-align: center;

    li {
      font-size: 1.25vw;
      cursor: pointer;
      line-height: 1;
      display: inline-block;
      margin: 0 0.78vw;
      font-weight: bold;
      position: relative;

    }

    .tabIsActive {
      color: #3759CB;

    }

    .tabIsActive::after {
      content: '';
      position: absolute;
      height: 7px;
      width: 2.1vw;
      min-width: calc(0.0208 * var(--minBaseWidth));
      background-color: rgb(28, 77, 197);
      top: 2.08vw;
      left: 50%;
      border-radius: 3px;
      transform: translate(-50%);
    }

    p {
      font-size: 25px;
      font-weight: bold;
      cursor: default;
      padding: 0;
      line-height: 60px;
      margin: 0;
    }
  }

  .login-content-form {
    padding: 44px 0.78vw 0;

    .el-form-item__error {
      position: relative;
      top: 0;
      text-align: left;
      width: 21.35vw;
      margin: 0 auto;
      min-width: calc(0.2135 * var(--minBaseWidth));
    }


    .register-phone-wrap__local {
      position: relative;
      width: 21.35vw;

      .el-input-group__prepend {
        width: 50px;
        padding: 0;
        border: 0;
        position: absolute;
        background: transparent;
        top: 50%;
        transform: translateY(-50%);

        .el-input__inner {
          text-align: center;
          border: 0;
          border-radius: 0;
          position: relative;

          height: 1.406vw;
          min-height: calc(0.01406 * var(--minBaseWidth));
          line-height: 0.625vw;
          vertical-align: middle;
          margin-left: 1px;
          padding: 10px 0;
          border-right: 1px solid #dcdfe6;
        }
      }

      .el-input__inner {
        padding-left: 60px;
        border-radius: var(--bottomRadiusValue);
        height: 2.97vw;
        line-height: 2.97vw;
        min-height: 32px;
      }
    }

    .phonecode-input-wrap {
      .el-input-group__append {
        border-left: 0;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
        background: rgb(28, 77, 197);
        color: white;
      }

      .el-button--default {
        padding: 7px 15px;
        font-size: 13px;

      }

      .el-input__inner {

        border-radius: var(--bottomRadiusValue);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right-width: 0;
        height: 2.97vw;
        line-height: 2.97vw;
        min-height: 32px;
      }

      width: 21.35vw;
    }

  }

  .login-btn-wrap {
    margin-top: 2.92vw;
    text-align: center;
    .login-button {

      color: #fff;
      background-color: rgb(28, 77, 197) !important;
      border-color: rgb(28, 77, 197) !important;
      border-radius: var(--bottomRadiusValue);
      height: 2.86vw;
      min-height: calc(0.02864 * var(--minBaseWidth));
      padding: 0.573vw 0.182vw;
      width: 21.35vw;
      min-width: calc(0.2135 * var(--minBaseWidth));
      border-radius: var(--bottomRadiusValue);
      font-size: 16px;

    }
  }

  .login-body {
    padding: 15px 0;
    color: #a1a1a1;
    font-size: 14px;
    text-align: center;

    #wechatcode {
      position: relative;
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }

    .wxlogin-failed-wrap {
      height: 220px;

      span {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
      }
    }
  }

  span {
    p {
      font-size: 17px;
      cursor: default;
      padding: 0;
      margin: 0;
    }
  }
}

.regist-wrap {
  .el-dialog {
    position: absolute;
    margin: 0 auto;
    margin-top: 0 !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }

  .el-dialog__header {
    padding: 35px 15px 20px !important;
    border-bottom: 1px solid #ddd !important;
  }

  .el-dialog__title {
    line-height: 24px;
    font-size: 20px;
    color: #3759CB !important;
    font-weight: 500;
  }

  .el-dialog__footer {
    padding: 10px 20px 20px;
    text-align: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #ddd;
    margin-top: -10px;

    .registdialog-footer {
      width: 100%;
      overflow: hidden;
      text-align: center;

      .el-button--primary.is-disabled, .el-button--primary.is-disabled:active,
      .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
        color: #fff !important;
        background-color: #c8c9cc !important;
        border-color: #c8c9cc !important;
      }
    }

    p {
      font-size: 13px;
      color: #606266;
      margin: 15px 0;

      a {
        color: #3759CB;
        margin-left: 8px;
      }
    }
  }

  .btn-all {
    background-color: #3399FF !important;
    border-color: #3399FF !important;
    padding: 10px 15px !important;
    width: 55%;
  }

}

.userpolicy-pbtn {
  display: block;
  font-size: 0.73vw;
  padding: 0;
  height: 0.78vw;
  line-height: 0.78vw;
  margin-top: 1.2vw;
}
</style>

